<template>
  <div class="py-4">
    <div v-if="blok.items?.length > 0" class="grid grid-cols-1 md:grid-cols-2 gap-4">
      <div v-for="item in blok.items" :key="item._uid">
        <div class="flex items-center">
          <div class="rounded-full bg-gray-100 p-2 flex">
            <component
              :is="theBlok.component"
              v-for="theBlok in item.icon.filter((item) =>
                ['IconBlok'].includes(item.component),
              )"
              :key="theBlok._uid"
              :blok="theBlok"
              class="relative h-8 w-8 top-1"
            />
          </div>
          <span v-if="item.title" class="pl-4 text-xl font-medium text-black">
            {{ item.title }}</span
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TitleIconBlok',
  props: {
    blok: {
      type: Object,
      required: true,
    },
  },
};
</script>
